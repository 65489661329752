import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";

import { connect } from "react-redux";
import { fetchAllPostCategories } from "./../../redux/actions/postCategory";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import {
  fetchSimpleFeed,
  like,
  likeStar,
  createComment,
  postComments,
  postCommentsOffset,
  postDelete,
  photosPost,
} from "../../redux/actions/postActions";

import FeedItemSimple from "../../components/FeedItemSimple";
import Select from 'react-select';
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";
import Loader from "../../components/Loader";


class TrendingFeedSimple extends Component {
  state = {
    feed: {},
    comment: "",
    comments: [],
    offset: 1,
    categories: [],
    activeCategory: { value: 0, label: "All categories",link:'/trending-feeds' },
    categoriesMenu: [],
    isLoading:true
  };
  componentDidMount() {
    this.props.fetchAllPostCategories().then((response) => {
      let categoriesMenu = [];
      categoriesMenu.push({ value: 0, label: "All categories", link: '/trending-feeds' });
      response.data.map((category, index) => {
        categoriesMenu.push({
          value: category.id,
          label: category.name,
          link: `/trending-feeds/category/${category.id}`
        });
      });

      this.setState({
        categories: [...response.data],
        categoriesMenu: [...categoriesMenu],
      });
    });
    this.props.fetchSimpleFeed(this.props.match.params.id).then((response) => {
      this.setState(
        {
          feed: { ...response.data },
          isLoading:false
        },
        () => {
          this.props.postComments(this.state.feed.id).then((response) => {
            this.setState({
              comments: [...response.data],
            });
          });
        }
      );
    });
  }

  like = (id_post, id_user) => {
    this.props.like(id_post, id_user).then((response) => {
      this.setState({ feed: { ...response.data } });
    });
  };

  likeStar = (id_post, id_user) => {
    this.props.likeStar(id_post, id_user).then((response) => {
      this.setState({ feed: { ...response.data } });
    });
  };

  createComment = (comment, id_post, id_user) => {
    this.props.createComment(comment, id_post, id_user).then((response) => {
      this.setState(
        {
          comment: "",
        },
        () => {
          this.props
            .postCommentsOffset(id_post, this.state.offset)
            .then((response) => {
              this.setState(
                {
                  comments: [...response.data],
                },
                () => {
                  this.props
                    .fetchSimpleFeed(this.props.match.params.id)
                    .then((response) => {
                      this.setState({
                        feed: { ...response.data },
                        comment: "",
                      });
                    });
                }
              );
            });
        }
      );
    });
  };

  deletePost = (id_post) => {
    this.props.postDelete(id_post);
  };
  seeMoreComments = (id_post) => {
    this.setState(
      {
        offset: this.state.offset + 1,
      },
      () => {
        this.props
          .postCommentsOffset(id_post, this.state.offset)
          .then((response) => {
            this.setState({
              comments: [...response.data],
            });
          });
      }
    );
  };
  setComment = (comment) => {
    this.setState({ comment });
  };
  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading}/>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <Select
                  options={this.state.categoriesMenu}
                  styles={stylesMobileSidebarSelect()}
                  className={"mb-2 mobile"}
                  defaultValue={this.state.activeCategory}
                  onChange={(e) => {

                    this.props.history.push(e.link);
                  }}
                />
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Categories</h2>
                  <ul className={"p-0"} style={{ listStyleType: "none" }}>
                    {this.state.categoriesMenu.map((category, index) => {
                      return (
                        <li
                          key={`feed_category_${index}`}
                          className={[
                             "",
                          ].join(" ")}
                        >
                          <a
                            href={""}
                            key={`home-category-${index}`}
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.history.push(category.link);
                            }}
                          >
                            {category.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {Object.keys(this.state.feed).length > 0 ? (
              <React.Fragment>
                <FeedItemSimple
                  feed={this.state.feed}
                  comments={this.state.comments}
                  offset={this.state.offset}
                  comment={this.state.comment}
                  like={this.like}
                  likeStar={this.likeStar}
                  createComment={this.createComment}
                  deletePost={this.deletePost}
                  seeMoreComments={this.seeMoreComments}
                  setComment={this.setComment}
                  callbackLink={`/trending-feeds`}
                />
              </React.Fragment>
            ) : null}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    fetchSimpleFeed: (id_post) => {
      return dispatch(fetchSimpleFeed(id_post));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    createComment: (comment, id_post, id_user) => {
      return dispatch(createComment(comment, id_post, id_user));
    },
    postComments: (id_post) => {
      return dispatch(postComments(id_post));
    },
    postCommentsOffset: (id_post, offset) => {
      return dispatch(postCommentsOffset(id_post, offset));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
    photosPost: (feed) => {
      return dispatch(photosPost(feed));
    },
    fetchAllPostCategories: () => {
      return dispatch(fetchAllPostCategories());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingFeedSimple);
