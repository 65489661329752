    import React from "react";
import { withRouter } from "react-router-dom";

function OrderBlock(props) {
  let files = JSON.parse(props.order.files);

  let statusRender = (status) => {
    switch (status) {
      case 0:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Request</div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Active</div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Rejected</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Closed</div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Refunded</div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Request Refund</div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Request Payment</div>
            </div>
          </div>
        );
      case 7:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Request Close</div>
            </div>
          </div>
        );
      case 8:
        return (
          <div className={"row description-block row-content"}>
            <div className={"col-12"}>
              <div className={"row-title"}>Status:</div>
              <div className={"description"}>Completed</div>
            </div>
          </div>
        );
        case 9:
            return (
                <div className={"row description-block row-content"}>
                    <div className={"col-12"}>
                        <div className={"row-title"}>Status:</div>
                        <div className={"description"}>Paid</div>
                    </div>
                </div>
            );
      default:
        return " ";
    }
  };

  return (
    <React.Fragment>
      <div className={"row justify-content-between order-row"}>
        <div className={"col-auto"}>
          <div className={"order-user"}>
            <span>
              <img
                className={"notification-img"}
                src={
                  props.settings.mainUrl + `/storage/${props.order.user.avatar}`
                }
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.history.push(
                    `/my-profile/highlights/${props.order.user.name}`
                  );
                }}
              />
            </span>

            <span className={"d-inline-block text-block"}>
              <div className={"name-badge"}>
                <div className={"name"}>
                  {" "}
                  {props.order.user.full_name}
                </div>
                <div className={"badge"}>
                  {
                    props.order.user.badge !== ''? <img src={require(`../../../assets/img/${props.order.user.badge}.png`)} width={15} />   : null
                  }

                </div>
              </div>
              <div className={"status"}>
                Placed an order for <strong>{props.order.service.name}</strong>
              </div>
            </span>
          </div>
        </div>
        <div className={"col-auto text-right"}>
          <img
            className={"order-img"}
            src={
              props.settings.mainUrl +
              `/storage/${props.order.service.description_image}`
            }
          />
        </div>
      </div>
      <div className={"row file-block row-content"}>
        <div className={"col-12"}>
          <div className={"row-title"}>Relevant Documents:</div>
          <div className={"file-list"}>
            {files.map((file, index) => {
              return (
                <a
                  href={props.settings.mainUrl + `/storage/${file}`}
                  className={"d-block"}
                  key={`order_${props.order.id}_${index}`}
                >
                  {file.split("/").pop()}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className={"row description-block row-content"}>
        <div className={"col-12"}>
          <div className={"row-title"}>Order Detail:</div>
          <div className={"description"}>{props.order.description}</div>
        </div>
      </div>
      {statusRender(props.order.status)}
      {props.order.feedback_buyer !== null ? (
        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>Buyer Review:</div>
            <div className={"description"}>{props.order.feedback_buyer}</div>
          </div>
        </div>
      ) : null}
      {props.order.feedback_seller !== null ? (
        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>Seller Review:</div>
            <div className={"description"}>{props.order.feedback_seller}</div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}
export default withRouter(OrderBlock);
