import React from "react";
import {NavLink} from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

function MobileNotificationNavbar() {
    return <div className={'mobile mobile-navbar-notification'}>
        <div className={'nav-list'}>
            <NavLink to={'/'} exact className={'nav-link active'}><FontAwesome name={'heart'} /></NavLink>
            <NavLink to={'/'} exact className={'nav-link'}><FontAwesome name={'briefcase'} /></NavLink>
            <NavLink to={'/'} exact className={'nav-link'}><FontAwesome name={'video-camera '} /></NavLink>
            <NavLink to={'/'} exact className={'nav-link'}><FontAwesome name={'calendar'} /></NavLink>
            <NavLink to={'/'} exact className={'nav-link'}><FontAwesome name={'dollar'} /></NavLink>
        </div>
    </div>;
}
export default MobileNotificationNavbar;