import React, { Component } from "react";
import DesktopNavbar from "../../../components/headers/DesktopNavbar";
import MobileNavbar from "../../../components/headers/MobileNavbar";
import BaseLayout from "../../../layouts/BaseLayout";
import DesktopNotificationSideBar from "../../../components/sidebars/DesktopNotificationsSidebar";
import MobileNotificationSidebar from "../../../components/sidebars/MobileNotificationSidebar";
import NotificationRightSidebar from "../../../components/sidebars/NotificationRightSidebar";
import Tabbar from "../../../components/headers/Tabbar";
import { listNotifications } from "../../../redux/actions/notificationActions";
import { connect } from "react-redux";
import {
  getFollows,
  subscribe,
  unsubscribe,
} from "../../../redux/actions/subscriptionsActions";
import {

  userNewData,
  userViewToken,

} from "../../../redux/actions/userActions";
import MobileNotificationNavbar from "../../../components/headers/MobileNotificationNavbar";
import Loader from "../../../components/Loader";

class Follows extends Component {
  state = {
    list: [],
    isLoading:true
  };

  componentDidMount() {
    this.props.listNotifications("follow").then((response) => {
      this.setState({
        list: [...response],
        isLoading:false
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading}/>
        <DesktopNavbar />
        <MobileNotificationNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Notifications</h2>
                  <DesktopNotificationSideBar
                    active={"follows"}
                    userLink={"/"}
                  />
                </div>
                <div className={"mobile"}>
                  <MobileNotificationSidebar
                    active={{
                      label: "Follows",
                      value: "/notification/follows",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            <div className="profile-account-holder  mb-3  bg-white post-item-list">
              {this.state.list.length === 0 ? <div className={'service-order-result d-flex justify-content-center align-items-center'} >
                No Follows!
              </div> : ''}
              <div className={" p-0   notification notification-block"}>
                {this.state.list.map((notice, index) => {
                  console.log(this.props.user.subscriptions.indexOf(
                      notice.id_user
                  ))
                  return (
                    <div
                      className={"notification-item  justify-content-between"}
                      key={`notice_${index}`}
                    >
                      <div className={'left-block'}>
                         <span>
                          <img
                            className={"notification-img"}
                            src={`${this.props.settings.mainUrl}/storage/${notice.user.avatar}`}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.props.history.push(
                                `/my-profile/highlights/${notice.user.name}`
                              );
                            }}
                          />
                        </span>

                        <span className={"d-inline-block text-block block-vertical-align"}>
                          <div className={"name-badge"}>
                            <div className={"name"}>
                              {notice.user.full_name}
                            </div>
                            <div className={"badge"}>
                              {
                                notice.user.badge !== ''? <img src={require(`../../../assets/img/${notice.user.badge}.png`)} width={15} />   : null
                              }
                            </div>
                          </div>
                          <div
                            className={"status"}
                            dangerouslySetInnerHTML={{ __html: notice.message }}
                          />
                        </span>
                      </div>
                      <div className={'right-block'}>
                        {this.props.user.subscriptions.indexOf(
                          notice.id_user
                        ) === -1 ? (
                          <button
                            className="btn btn-primary btn-radius-50 btn-follow"
                            type={"button"}
                            onClick={() => {
                              this.props
                                .subscribe(
                                  this.props.user.id,
                                  notice.id_user
                                )
                                .then((response) => {
                                  this.props
                                    .userViewToken()
                                    .then((response) => {
                                      this.props.userNewData(response.data);
                                    });
                                });
                            }}
                          >
                            Follow Back
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-radius-50 btn-following"
                            type={"button"}
                            onClick={() => {
                              this.props
                                .unsubscribe(
                                  this.props.user.id,
                                  notice.id_user
                                )
                                .then((response) => {
                                  this.props
                                    .userViewToken()
                                    .then((response) => {
                                      this.props.userNewData(response.data);
                                    });
                                });
                            }}
                          >
                            Following
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <NotificationRightSidebar user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    listNotifications: (type) => {
      return dispatch(listNotifications(type));
    },
    getFollows: (id) => {
      return dispatch(getFollows(id));
    },
    subscribe: (id_user1, id_user2) => {
      return dispatch(subscribe(id_user1, id_user2));
    },
    unsubscribe: (id_user1, id_user2) => {
      return dispatch(unsubscribe(id_user1, id_user2));
    },
    userViewToken: () => {
      return dispatch(userViewToken());
    },
    userNewData: (data) => {
      dispatch(userNewData(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Follows);
