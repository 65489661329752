import React from "react";
import { connect } from "react-redux";
import "./App.css";
import Login from "./containers/Login/Login";
import Registration from "./containers/Registration/Registration";

import "./containers/MyProfile/MyProfile";
import MyProfile from "./containers/MyProfile/MyProfile";
import Profile from "./containers/Profile/Profile";
import { Switch, Route, Redirect } from "react-router-dom";
import EmailVerify from "./containers/EmailVerify";
import RegistrationThankYou from "./containers/Registration/RegistrationThankYou";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ResetForgotPassword from "./containers/ForgotPassword/ResetForgotPassword";
import Feed from "./containers/Feed/Feed";
import MyProfileServices from "./containers/MyProfileServices/MyProfileServices";
import MyProfileHighlights from "./containers/MyProfileHighlights/MyProfileHighlights";
import Home from "./containers/Home/Home";
import Communities from "./containers/Communities/Communities";
import CreatePost from "./containers/CreatePost/CreatePost";
import ProtectedRoute from "./components/ProtectedRoute";
import GuestRoute from "./components/GuestRoute";
import "react-image-gallery/styles/css/image-gallery.css";
import Messenger from "./containers/Messenger/components/Messenger";
import SimplePost from "./containers/SimplePost/SimplePost";
import MainAccountSettings from "./containers/AccountSettings/MainAccountSettings";
import TwilioTest from "./containers/Twilio/TwilioTest";
import TwilioRoomTest from "./containers/Twilio/TwilioRoomTest";
import StripeTest from "./containers/StripeTest/StripeTest";
import SimpleService from "./containers/SimpleService/SimpleService";
import ServiceOrderSuccess from "./containers/SimpleService/ServiceOrderSuccess";
import ServicePlaceOrder from "./containers/SimpleService/ServicePlaceOrder";
import CreateService from "./containers/CreateService/CreateService";
import NotificationsOrders from "./containers/Notifications/Orders/NotificationsOrders";
import OrderRequests from "./containers/Notifications/Orders/OrderRequests";
import CallsNotification from "./containers/Notifications/Calls/CallsNotification";
import InProgress from "./containers/Notifications/Orders/InProgress";
import Completed from "./containers/Notifications/Orders/Completed";
import MadeNotifications from "./containers/Notifications/MadeOrders/MadeNotifications";
import MadeRequest from "./containers/Notifications/MadeOrders/MadeRequest";
import MadeInProgress from "./containers/Notifications/MadeOrders/MadeInProgress";
import MadeCompleted from "./containers/Notifications/MadeOrders/MadeCompleted";
import Likes from "./containers/Notifications/Likes/Likes";
import Awards from "./containers/Notifications/Awards/Awards";
import Comments from "./containers/Notifications/Comments/Comments";
import Follows from "./containers/Notifications/Follows/Follows";
import EditPost from "./containers/EditPost/EditPost";
import NotFound from "./containers/NotFound/NotFound";
import MyProfileExhibits from "./containers/MyProfileExhibits/MyProfileExhibits";
import TrendingFeed from "./containers/Trending/TrendingFeed";
import TrendingExhibits from "./containers/Trending/TrendingExhibits";
import TrendingServices from "./containers/Trending/TrendingServices";
import EditService from "./containers/EditService/EditService";
import SimpleFeed from "./containers/Feed/SimpleFeed";
import MyProfileExhibitsSimple from "./containers/MyProfileExhibits/MyProfileExhibitsSimple";
import MyProfileHighlightsSimple from "./containers/MyProfileHighlights/MyProfileHighlightsSimple";
import TrendingFeedSimple from "./containers/Trending/TrendingFeedSimple";
import TrendingExhibitsSimple from "./containers/Trending/TrendingExhibitsSimple";
import HomeSimple from "./containers/Home/HomeSimple";
import PaymentError from "./containers/Payments/PaymentError";
import PaymentSuccess from "./containers/Payments/PaymentSuccess";
import Earning from "./containers/Notifications/Earning/Earning";
import RequestCall from "./containers/RequestCall/RequestCall";
import CallRequests from "./containers/Notifications/Calls/CallRequests"
import CallUpComing from "./containers/Notifications/Calls/CallUpComing";
import CallCompleted from "./containers/Notifications/Calls/CallCompleted";
import AccountSettings from "./containers/AccountSettings/AccountSettings";
import PaymentSettings from "./containers/AccountSettings/PaymentSettings";
import BadgesSettings from "./containers/AccountSettings/BadgesSettings";
import HelpSettings from "./containers/AccountSettings/HelpSettings";
import AboutSettings from "./containers/AccountSettings/AboutSettings";
import ContentPolicy from "./containers/TextPages/ContentPolicy";
import TermsOfServices from "./containers/TextPages/TermsOfServices";
import SearchPage from "./containers/SearchPage/SearchPage";
import StripeCustomer from "./containers/StripeCustomer/StripeCustomer";
import {NotificationContainer} from 'react-notifications';


function App() {
  return (
    <div className="App">
      <Switch>
        <GuestRoute
          component={EmailVerify}
          exact
          path={"/verify-email/queryURL/:url+"}
        />
        <GuestRoute component={Registration} exact path={"/register"} />
        <GuestRoute component={Login} exact path={"/login"} />
        <GuestRoute component={RegistrationThankYou} path={"/registered/:id"} />
        <GuestRoute path={"/forgot-password"} component={ForgotPassword} />
        <GuestRoute
          path={"/reset-password/token/:token/email/:email"}
          component={ResetForgotPassword}
        />
        <ProtectedRoute path={"/communities"} component={Communities} />
        <ProtectedRoute path={"/my-profile/feed/:user?"} component={Feed} />
        <ProtectedRoute path={"/my-profile-feed/:id"} component={SimpleFeed} />
        <ProtectedRoute
          path={"/my-profile-exhibit/:id"}
          component={MyProfileExhibitsSimple}
        />
        <ProtectedRoute
          path={"/my-profile-highlight/:id"}
          component={MyProfileHighlightsSimple}
        />
        <ProtectedRoute
          path={"/my-profile/services/:user?"}
          component={MyProfileServices}
        />
        <ProtectedRoute
          path={"/my-profile/highlights/:user?"}
          component={MyProfileHighlights}
        />
        <ProtectedRoute
          path={"/my-profile/exhibits/:user?"}
          component={MyProfileExhibits}
        />

        <ProtectedRoute
          component={MyProfile}
          path={"/my-profile/about/:user?"}
          exact
        />

        <ProtectedRoute path={"/create-post"} component={CreatePost} />
        <ProtectedRoute path={"/edit-post/:id"} component={EditPost} />
        <ProtectedRoute path={"/messenger/:user?"} component={Messenger} />
        <ProtectedRoute path={"/feed/:id"} component={HomeSimple} />

        <ProtectedRoute
          path={"/settings/general"}
          component={MainAccountSettings}
        />
        <ProtectedRoute
          path={"/settings/account"}
          component={AccountSettings}
        />
        <ProtectedRoute
          path={"/settings/payments"}
          component={PaymentSettings}
        />
        <ProtectedRoute
          path={"/settings/badges"}
          component={BadgesSettings}
        />
        <ProtectedRoute
          path={"/settings/help"}
          component={HelpSettings}
        />
        <ProtectedRoute
          path={"/settings/about"}
          component={AboutSettings}
        />

        <ProtectedRoute
          path={"/twilio-test/room/:roomName"}
          component={TwilioRoomTest}
        />
        <ProtectedRoute path={"/twilio-test"} component={TwilioTest} />
        <ProtectedRoute path={"/stripe-test/:id?"} component={StripeTest} />
        <ProtectedRoute
          path={"/payment-success/:id"}
          component={PaymentSuccess}
        />
        <ProtectedRoute path={"/payment-error/:id"} component={PaymentError} />
        <ProtectedRoute path={"/service/:id"} component={SimpleService} />
        <ProtectedRoute
          path={"/service-order-result"}
          component={ServiceOrderSuccess}
        />
        <ProtectedRoute
          path={"/service-place-order/:service"}
          component={ServicePlaceOrder}
        />
        <ProtectedRoute path={"/create-service"} component={CreateService} />
        <ProtectedRoute path={"/edit-service/:id"} component={EditService} />
        <ProtectedRoute
          path={"/notification/receive-orders/notifications"}
          component={NotificationsOrders}
        />
        <ProtectedRoute
          path={"/notification/receive-orders/requests"}
          component={OrderRequests}
        />
        <ProtectedRoute
          path={"/notification/receive-orders/in-progress"}
          component={InProgress}
        />
        <ProtectedRoute
          path={"/notification/receive-orders/completed"}
          component={Completed}
        />{" "}
        <ProtectedRoute
          path={"/notification/orders-created/notifications"}
          component={MadeNotifications}
        />
        <ProtectedRoute
          path={"/notification/orders-created/requests"}
          component={MadeRequest}
        />
        <ProtectedRoute
          path={"/notification/orders-created/in-progress"}
          component={MadeInProgress}
        />
        <ProtectedRoute
          path={"/notification/orders-created/completed"}
          component={MadeCompleted}
        />{" "}

        <ProtectedRoute path={"/notification/likes"} component={Likes} />
        <ProtectedRoute path={"/notification/awards"} component={Awards} />
        <ProtectedRoute path={"/notification/comments"} component={Comments} />
        <ProtectedRoute path={"/notification/follows"} component={Follows} />

        <ProtectedRoute path={"/notification/calls"} component={CallsNotification} />
        <ProtectedRoute path={"/notification/calls-requests"} component={CallRequests}/>
        <ProtectedRoute path={"/notification/calls-up-coming"} component={CallUpComing}/>
        <ProtectedRoute path={"/notification/calls-completed"} component={CallCompleted}/>

        <ProtectedRoute path={"/notification/earning"} component={Earning}/>
        <ProtectedRoute path={"/trending-feeds/category/:id"} component={TrendingFeed} />
        <ProtectedRoute path={"/trending-feeds"} component={TrendingFeed} />
        <ProtectedRoute
          path={"/trending-feed/:id"}
          component={TrendingFeedSimple}
        />
        {/*<ProtectedRoute*/}
        {/*  path={"/trending-exhibit/:id"}*/}
        {/*  component={TrendingExhibitsSimple}*/}
        {/*/>*/}
        {/*<ProtectedRoute*/}
        {/*  path={"/trending/exhibits"}*/}
        {/*  component={TrendingExhibits}*/}
        {/*/>*/}
        {/*<ProtectedRoute*/}
        {/*  path={"/trending/services"}*/}
        {/*  component={TrendingServices}*/}
        {/*/>*/}
        <ProtectedRoute path={'/search-page/:search'} component={SearchPage}/>
        <ProtectedRoute path={'/search-page'} component={SearchPage}/>
        <ProtectedRoute path={'/terms-of-service'} component={TermsOfServices}/>
        <ProtectedRoute path={'/content-policy'} component={ContentPolicy}/>
        <ProtectedRoute path={"/request-call/:user"} component={RequestCall}/>
        <ProtectedRoute path={"/stripe-customer"} component={StripeCustomer}/>
        <ProtectedRoute path={"/home/category/:id"} component={Home}/>
        <ProtectedRoute component={Home} exact path={"/"} />
        <ProtectedRoute component={NotFound} />
      </Switch>
      <NotificationContainer/>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}
export default    connect(mapStateToProps)(  App);
