import React, {useEffect, useState} from 'react';
import SlidingPanel from "react-sliding-panel";
import FontAwesome from "react-fontawesome";
import SwipeButton from "react-swipezor";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";
import {setPaymentToUser} from "../redux/actions/stripeActions";
import {connect} from "react-redux";
import {changeStatus} from "../redux/actions/orderActions";

const stripePromise = loadStripe("pk_test_Du0fzY5XkR7m8qkwrWanhqpC00TMxFfEet");

function ServicePayment(props) {
    let [cardElement, setCard] = useState({});
    let [stripe, setStripe] = useState({});
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [errors, setErrors] = useState({
        name:[],
        email:[],


    });
    let [isCardComplete, setIsCardComplete] = useState(false);
    const [reset, setReset] = useState(0);

    let getErorrList = () => {

        let errorsList = [];
        let keys = Object.keys(errors);

        keys.map((item,index) => {

            errorsList = errorsList.concat(errors[item]);


        });

        return errorsList;
    }

    let getPaymentIntent = async (customer) => {
        return await axios.post(`${props.settings.mainUrl}/api/stripe/payment-service`,{
            id_service:props.order.id_service,
            id_customer:customer.data.id,
            api_token:props.user.api_token
        });
    }

    let validateForm = ()  => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errorsList = {
            name: [],
            email:[]
        };

        if (name === "") {
            errorsList.name.push('Name is required!');
        }
        if(email === ""){
            errorsList.email.push('Email is required!');
        }
        if (!re.test(email)) {
            errorsList.email.push('Email not valid!');
        }


        return errorsList;
    }

    let checkErrors = (errorsList) => {
        let isError = false;
        Object.keys(errorsList).map((objectKey, index) => {
            let value = errorsList[objectKey];
            if (value.length > 0) {
                isError = true;
            }
        });
        return isError;
    };

    let payHandler = async () => {
        let customer = await axios.post(`${props.settings.mainUrl}/api/stripe/payment/customer/${props.user.id}`,{
            api_token:props.user.api_token
        });


        let paymentIntent = await getPaymentIntent(customer);


        stripe.confirmCardPayment(paymentIntent.data.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {

                    name: name,
                    email:email,
                }
            },
            setup_future_usage: 'off_session'
        }).then((result) => {
            if (result.error) {
                console.log('error')
                // Show error to your customer

            } else {
                console.log('success')
                if (result.paymentIntent.status === 'succeeded') {

                    axios.patch(`${props.settings.mainUrl}/api/user/${props.user.id}/customer/${customer.data.id}`,{
                        api_token:props.user.api_token
                    });
                    axios.patch(`${props.settings.mainUrl}/api/user/${props.user.id}/payment_method/${result.paymentIntent.payment_method}`,{
                        api_token:props.user.api_token
                    });

                    props.setPaymentToUser('service',props.order,result.paymentIntent).then(response => {

                        props.changeStatus(props.order.id,9)


                        props.hidePayment();
                        window.location.href = '/';
                    })

                }
            }
        });


    }


    useEffect(() => {

        stripePromise.then(response => {
            let elements = response.elements();
            let style = {
                base: {
                    iconColor: '#c4f0ff',
                    color: '#333',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '13px',

                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#fce883',
                    },
                    '::placeholder': {
                        color: '#333',
                    },
                },
                invalid: {
                    iconColor: '#FFC7EE',
                    color: '#000',
                },
            };

            let card = elements.create("card", {
                style: style,
                hidePostalCode : true
            });

            card.mount("#card-element");
            card.on('change', function(event) {
                let displayError = document.getElementById('card-errors');

                if (event.error) {
                    setIsCardComplete(false);
                    displayError.textContent = event.error.message;
                } else {
                    setIsCardComplete(true)
                    displayError.textContent = '';
                }
            });
            setCard(card);
            setStripe(response);
        })
    },[]);

    return     <div className={'slide-panel-container blue-panel'}>
        <SlidingPanel type={'bottom'} isOpen={props.isOpen} closeFunc={() => {props.closePanel()}} className={'black'}>
            <div className={'header-panel'}>
                <a href={''} onClick={event => {
                    event.preventDefault();
                    props.closePanel();
                }}>
                    <FontAwesome name={'times'}/>
                </a>
                <span className={'title'}>Place Order</span>
                <span></span>
            </div>
            <div className={'body-panel '}>
                <div className={'exhibit-place-order'}>

                    {
                        props.user.customer_id !== null && props.user.payment_method_id !== null ?
                            <div className={'feed-img'}>
                                <img src={props.settings.mainUrl + `/storage/${props.service.description_image}`} />
                            </div> :
                            null

                    }

                    <div className={'name'}>
                        {props.service.name}
                    </div>
                    <div className={'total-price'}>
                        <span className={'title'}>Total:</span>
                        <span>{props.service.price.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</span>
                    </div>
                    <div>

                    </div>
                    <div style={{
                        display:props.user.customer_id === null || props.user.payment_method_id === null ? 'block' : 'none'
                    }}>
                        <div className={'form-group'}>
                            <label>Name</label>
                            <input className={[
                                'form-control bordered',
                                errors.name.length > 0 ?'error' : ''
                            ].join(' ')} value={name} onChange={e => setName(e.target.value)}/>
                            <div className={'error'}>{errors.name.join(' ')}</div>
                        </div>
                        <div className={'form-group'}>
                            <label>Email</label>
                            <input className={[
                                'form-control bordered',
                                errors.email.length > 0 ?'error' : ''
                            ].join(' ')} value={email} onChange={e => setEmail(e.target.value)}/>
                            <div className={'error'}>{errors.email.join(' ')}</div>
                        </div>
                        <div id="card-element" className={'mb-3'}>

                        </div>


                        <div id="card-errors" role="alert"></div>


                        <SwipeButton mainText={'Slide to place your order'} reset={reset} overlayText={'Payment'} onSwipeDone={() => {
                            let errorsList = validateForm();
                            setErrors({...errorsList});
                            if(!checkErrors(errorsList) && isCardComplete){

                                payHandler().then(response => {

                                });


                            }
                            else{
                                setReset(counter => counter + 1)
                                getErorrList(errorsList);
                            }
                        }} />


                    </div>


                    <div style={{
                        display:props.user.customer_id === null || props.user.payment_method_id === null ? 'none' : 'block'
                    }}>
                        <SwipeButton mainText={'Slide to place your order'} overlayText={'Payment'} onSwipeDone={() => {
                            props.paymentOrder();
                        }} />
                    </div>



                </div>
            </div>
        </SlidingPanel>
    </div>;
}
function mapStateToProps(state) {
    return {
        user: state.user.user,
        settings: state.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPaymentToUser:(type,data,paymentIntent) => {
            return dispatch(setPaymentToUser(type, data, paymentIntent))
        },
        changeStatus:(id_order, status) => {
            return dispatch(changeStatus(id_order,status))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServicePayment);