import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import ImageGallery from "react-image-gallery";
import { connect } from "react-redux";

import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import {
  fetchSimpleFeed,
  like,
  likeStar,
  createComment,
  postComments,
  postCommentsOffset,
  postDelete,
  photosPost,
} from "../../redux/actions/postActions";
import FeedContextMenu from "../../components/FeedContextMenu/FeedContextMenu";
import FeedItemSimple from "../../components/FeedItemSimple";
import Loader from "../../components/Loader";

class MyProfileHighlightsSimple extends Component {
  state = {
    feed: {},
    comment: "",
    comments: [],
    offset: 1,
    isLoading:true
  };
  componentDidMount() {
    this.props.fetchSimpleFeed(this.props.match.params.id).then((response) => {
      this.setState(
        {
          feed: { ...response.data },
        },
        () => {
          this.props.postComments(this.state.feed.id).then((response) => {
            this.setState({
              comments: [...response.data],
              isLoading:false
            });
          });
        }
      );
    });
  }

  like = (id_post, id_user) => {
    this.props.like(id_post, id_user).then((response) => {
      this.setState({ feed: { ...response.data } });
    });
  };

  likeStar = (id_post, id_user) => {
    this.props.likeStar(id_post, id_user).then((response) => {
      this.setState({ feed: { ...response.data } });
    });
  };

  createComment = (comment, id_post, id_user) => {
    this.props.createComment(comment, id_post, id_user).then((response) => {
      this.setState(
        {
          comment: "",
        },
        () => {
          this.props
            .postCommentsOffset(id_post, this.state.offset)
            .then((response) => {
              this.setState(
                {
                  comments: [...response.data],
                },
                () => {
                  this.props
                    .fetchSimpleFeed(this.props.match.params.id)
                    .then((response) => {
                      this.setState({
                        feed: { ...response.data },
                        comment: "",
                      });
                    });
                }
              );
            });
        }
      );
    });
  };

  deletePost = (id_post) => {
    this.props.postDelete(id_post);
  };
  seeMoreComments = (id_post) => {
    this.setState(
      {
        offset: this.state.offset + 1,
      },
      () => {
        this.props
          .postCommentsOffset(id_post, this.state.offset)
          .then((response) => {
            this.setState({
              comments: [...response.data],
            });
          });
      }
    );
  };
  setComment = (comment) => {
    this.setState({ comment });
  };
  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading}/>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.props.user.name}</h2>
                  <MyProfileSidebar active={"highlights"} isOwner={Object.keys(this.state.feed).length > 0 && this.state.feed.user.name === this.props.user.name} userLink={
                    Object.keys(this.state.feed).length > 0
                      ? `/${this.state.feed.user.name}`
                      : ""
                  } />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    isOwner={Object.keys(this.state.feed).length > 0 && this.state.feed.user.name === this.props.user.name}
                    userLink={
                      Object.keys(this.state.feed).length > 0
                        ? `/${this.state.feed.user.name}`
                        : ""
                    }
                    active={{
                      value: "/my-profile/highlights",
                      label: "Highlights",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {Object.keys(this.state.feed).length > 0 ? (
              <React.Fragment>
                <FeedItemSimple
                  feed={this.state.feed}
                  comments={this.state.comments}
                  offset={this.state.offset}
                  comment={this.state.comment}
                  like={this.like}
                  likeStar={this.likeStar}
                  createComment={this.createComment}
                  deletePost={this.deletePost}
                  seeMoreComments={this.seeMoreComments}
                  setComment={this.setComment}
                  callbackLink={`/my-profile/highlights/${this.state.feed.user.name}`}
                />
              </React.Fragment>
            ) : null}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    fetchSimpleFeed: (id_post) => {
      return dispatch(fetchSimpleFeed(id_post));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    createComment: (comment, id_post, id_user) => {
      return dispatch(createComment(comment, id_post, id_user));
    },
    postComments: (id_post) => {
      return dispatch(postComments(id_post));
    },
    postCommentsOffset: (id_post, offset) => {
      return dispatch(postCommentsOffset(id_post, offset));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
    photosPost: (feed) => {
      return dispatch(photosPost(feed));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfileHighlightsSimple);
