import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";

import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import {
  trendingFeed,
  like,
  likeStar,
  postDelete,
  unlike,
  fetchSimpleFeed
} from "../../redux/actions/postActions";
import { getUserById, getUserByName } from "../../redux/actions/userActions";
import { connect } from "react-redux";

import FeedItem from "../../components/FeedItem";
import { fetchAllPostCategories } from "./../../redux/actions/postCategory";
import Select from "react-select";
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";
import NotFoundBlock from "../NotFound/NotFoundBlock";
import Loader from "../../components/Loader";

class TrendingFeed extends Component {
  state = {
    feed: [],
    categories: [],
    activeCategory: { value: 0, label: "All categories",link:'/trending-feeds' },
    categoriesMenu: [],
    isLoading:true
  };

  componentDidMount() {
    this.fetchTrendingFeed();
    this.props.fetchAllPostCategories().then((response) => {
      let categoriesMenu = [];
      categoriesMenu.push({ value: 0, label: "All categories",link:'/trending-feeds' });
      response.data.map((category, index) => {
        categoriesMenu.push({ value: category.id, label: category.name, link:`/trending-feeds/category/${category.id}` });
      });

      this.setState({
        categories: [...response.data],
        categoriesMenu: [...categoriesMenu],
      });




    });

  }

  setSimpleFeed = (id, index) => {
    this.props.fetchSimpleFeed(id).then(response => {
      let feeds = [...this.state.feed];
      console.log(feeds[index], response.data);
      feeds[index] = { ...response.data };

     this.setState({ feed: [...feeds] });
    })
  }

  fetchTrendingFeed = () => {
    this.props.trendingFeed(this.props.match.params.id).then((response) => {
      this.setState({
        feed: [...response.data],
        isLoading:false
      });
    });
  }

  deleteFromList = (index) => {
    let feeds = [...this.state.feed];
    feeds.splice(index, 1);
    this.setState({
      feed: [...feeds],
    });
  };

  likeStar = (feed, index) => {
    this.props.likeStar(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  like = (feed, index) => {
    this.props.like(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.location.pathname !== prevProps.location.pathname){
      this.fetchTrendingFeed();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.isLoading}/>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <Select
                  options={this.state.categoriesMenu}
                  styles={stylesMobileSidebarSelect()}
                  className={"mb-2 mobile"}
                  defaultValue={this.state.activeCategory}
                  onChange={(e) => {

                    this.props.history.push(e.link);
                  }}
                />
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Categories</h2>
                  <ul className={"p-0"} style={{ listStyleType: "none" }}>
                    {this.state.categoriesMenu.map((category, index) => {
                      return (
                        <li
                          key={`feed_category_${index}`}
                          className={[
                            parseInt(this.props.match.params.id)  === category.value || (this.props.match.path === '/trending-feeds' && category.value === 0)
                              ? "active"
                              : "",
                          ].join(" ")}
                        >
                          <a
                            href={""}
                            key={`home-category-${index}`}
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.history.push(category.link);
                            }}
                          >
                            {category.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {this.state.feed.length === 0 ? <NotFoundBlock/>  : null}
            {this.state.feed.map((feed, index) => {
              return (
                <FeedItem
                  key={`trending-feed-${index}`}
                  feed={feed}
                  index={index}
                  deleteFromList={this.deleteFromList}
                  likeStar={this.likeStar}
                  like={this.like}
                  feedLink={`/trending-feed/${feed.id}`}
                  setSimpleFeed={this.setSimpleFeed}
                />
              );
            })}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    unlike: (id_post, id_user) => {
      return dispatch(unlike(id_post, id_user));
    },
    trendingFeed: (id_category) => {
      return dispatch(trendingFeed(id_category));
    },
    getUserById: (id_user) => {
      return dispatch(getUserById(id_user));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
    fetchAllPostCategories: () => {
      return dispatch(fetchAllPostCategories());
    },
    fetchSimpleFeed: (id_post) => {
      return dispatch(fetchSimpleFeed(id_post));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingFeed);
